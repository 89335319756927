body {
  font-family: "Jost", sans-serif;
}

h1,
h2,
h3 {
  font-family: "Jost", sans-serif;
}

#wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

main {
  min-height: 2000px;
}