@font-face {
  font-family: "Jost";
  src: url("../fonts/Jost-300-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Jost";
  src: url("../fonts/Jost-400-Book.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Jost";
  src: url("../fonts/Jost-500-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Jost";
  src: url("../fonts/Jost-600-Semi.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Jost";
  src: url("../fonts/Jost-700-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Jost";
  src: url("../fonts/Jost-800-Heavy.ttf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Jost";
  src: url("../fonts/Jost-900-Black.ttf");
  font-weight: 900;
  font-style: normal;
}