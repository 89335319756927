.home {

  &--intro {
    position: relative;
    padding-top: 10rem;
    padding-bottom: 10rem;

    &-text {
      position: absolute;
      z-index: 9;
      top: 40%;
      left: 10%;
      transform: translateY(-50%);

      h1 {
        margin: 0;
        font-size: 5rem;
        font-weight: 300;
        max-width: 60%;

        strong {
          font-weight: 600;
        }
      }
    }

    &-images {
      display: flex;
      justify-content: space-between;
    }

    &-image {

      &:first-child {
        position: relative;
        top: 50px;
      }

      &:last-child {

        img {
          width: 90%;
        }
      }
    }

    img {
      display: block;
      width: 70%;
      height: auto;
      opacity: 0.5;
    }
  }

  &--about {
    max-width: 900px;
    margin: 0 auto;
    padding: 3rem 1rem;
    display: flex;
    align-items: center;

    &-skills {
      flex: 1;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
      }

      li {
        font-size: 2rem;
      }
    }

    &-text {
      flex: 1;

      p {
        line-height: 1.6;
        font-weight: 300;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}